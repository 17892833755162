.download-btn {
  position: absolute;
  top: -5px;
  right: -15px;
  width: 10px;
  height: 10px;
  color: #ccc;
  min-width: 169px;
  text-align: center;
  /* background: #EBEBEB; */
  font-size: 16px;
  font-weight: 400;
  font-family: Raleway, sans-serif;
}

.table-row {
  /* width: 100%; */
  /* height: 10px; */
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: lighter;
  /* line-height: 35px; */
}

.table-hed {
  background: #ebebeb;
  width: 100%;
  /* height: 50px; */
  font-weight: 900;
  color: #000;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  line-height: 20px;
}

.table-column {
  width: 100%;
  padding: 15px;
  letter-spacing: 0px;

  color: #0a0a0a;
  font: normal normal bold 18px/22px Raleway;
  /* height: 10px; */
  /* color: #000;
	font-weight: bold;
	font-family: Raleway, sans-serif;
	font-size: 18px;
	line-height: 35px; */
}

.table-column1 {
  width: 100%;
  padding: 15px;
  color: #4b4c4c;
  font: normal normal 16px/19px Raleway;
  letter-spacing: 0px;

  /* height: 10px; */
  /* color: #000;
	font-weight: bold;
	font-family: Raleway, sans-serif;
	font-size: 18px;
	line-height: 35px; */
}

.title-content {
  font: normal normal normal 16px/19px Raleway;
  letter-spacing: 0.4px;
  color: #646464;
}

.title {
  font: normal normal bold 25px/30px Raleway;
  letter-spacing: 0px;
  color: #0a5c7f;
  opacity: 1;
}

.title-main {
  font: normal normal bold 18px/22px Raleway;
  letter-spacing: 0px;
  color: #4b4c4c;
}

.donut-title {
  font: normal normal normal 16px/19px Raleway;
  letter-spacing: 0.32px;
  color: #646464;
  opacity: 0.9;
}