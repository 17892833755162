.table-main-wrap {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
	width: 100%;
	overflow: auto;
	padding: 30px 30px 17px;
	margin-top: 10px;
}
.MuiTablePagination-root {
	overflow: inherit;
}
.MuiTableHead-root {
	background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
	text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
	background: #dff0d8;
	border-radius: 2px;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #3c763d;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 3px 5px;
	min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
	color: #a94442;
	background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
	padding: 19px 0 19px 12px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
	color: #646464;
	font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-root {
	border-bottom: 1px solid #f5f7fa;
	letter-spacing: 0;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #646464;
	padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
	border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.button-field.button-field-assetsize {
	display: inline-block;
	vertical-align: middle;
	float: right;
	margin-top: 2px;
}
.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
	background-color: #fff;
	border-radius: 2px;
	color: #646464;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
	padding-top: 0;
	padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
	width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
	width: 122px;
	min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
	min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
	min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
	min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
	background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
	padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
	display: inline-block;
	vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
	margin-left: 5px;
}

.table-pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 40px;
	align-items: center;
}

.pagination-info {
	margin-left: auto;
	padding-left: 404px;
}

.pagination-control {
	margin-left: auto;
}

.pagination-control > * {
	display: inline-block;
	vertical-align: middle;
	margin-left: 30px;
}

.pagination-control span {
	font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
	background: #fff;
	width: 50px;
	padding: 0;
	min-width: 1px;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	text-align: center;
	border-radius: 3px;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin-left: 10px;
	padding: 5px 6px;
	color: #4b4c4c;
}

.pagination-control select {
	background-color: #fff;
	width: 54px;
	border-radius: 3px;
	padding: 0;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	padding: 6px;
	color: #4b4c4c;
}

.pagination-control input + span {
	margin-left: 10px;
}

.pagination-info p {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin: 0;
	color: #4b4c4c;
}

select {
	background-image: url("../../../../assets/images/down-arrow-select.svg");
}
.form-main {
	background: #fff;
	margin-top: 43px;
	padding: 47px 50px;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
	max-width: 813px;
}

@media (max-width: 1500px) {
	.form-main {
		padding: 35px 35px;
	}
	.pagination-control > * {
		margin-left: 20px;
	}

	.pagination-info {
		padding-left: 352px;
	}
	.table-main-wrap {
		padding: 20px 20px 10px;
	}
}

@media (max-width: 1199px) {
	.button-field.button-field-assetsize {
		margin-top: -4px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 15px;
		padding: 14px 10px 14px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 100px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		min-width: 120px;
	}
	.MuiTableRow-root .MuiTableCell-body.green span,
	.MuiTableRow-root .MuiTableCell-body.red span {
		font-size: 15px;
	}

	.pagination-info {
		padding-left: 0;
		margin: 0;
	}

	.table-pagination {
		margin-top: 30px;
	}
	.field-left {
		width: 30%;
	}

	.field-right {
		width: 70%;
	}

	.form-submit .button-field input {
		min-width: 175px;
	}

	.form_field {
		margin-bottom: 35px;
	}

	.form-main {
		margin-top: 30px;
	}
}

@media (max-width: 991px) {
	.button-field.button-field-assetsize {
		margin-top: 0;
	}

	.table-main-wrap {
		margin-top: 28px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 16px;
		padding: 18px 12px 18px 12px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 80px;
	}
	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 27px 12px 26px 12px;
	}

	.table-pagination {
		margin-top: 22px;
	}

	.pagination-control > * {
		margin-left: 30px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 127px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 111px;
	}
	.form-main {
		margin-top: 28px;
		padding: 30px 30px 195px;
		margin-bottom: 0;
	}

	.field-left {
		width: 32%;
	}

	.field-right {
		width: 68%;
	}

	.form-main form {
		max-width: 511px;
	}

	.form_field {
		margin-bottom: 49px;
	}

	.form-submit .button-field input {
		min-width: 143px;
	}

	.form-main .form_field {
		padding-right: 16px;
	}
}

@media (max-width: 767px) {
	.MuiTableRow-root .MuiTableCell-root {
		font-size: 14px;
	}

	.button-field.button-field-assetsize button {
		font-size: 12px;
		padding: 8px 20px 8px;
		min-width: 108px;
	}

	.table-main-wrap {
		margin-top: 25px;
		padding: 14px 15px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		padding: 12px 12px;
	}

	.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
		padding-bottom: 29px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 60px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 95px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 109px;
	}

	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 15px 12px 14px;
	}

	.pagination-info {
	}

	.pagination-info p {
		font-size: 12px;
	}

	.pagination-control > * {
		margin-left: 14px;
	}

	.pagination-control span {
		font-size: 12px;
	}

	.pagination-control a img {
		max-height: 8px;
	}

	.pagination-control select {
		font-size: 12px;
		background-size: 7px;
		background-position: center right 5px;
		padding: 5px 14px 5px 5px;
		width: 35px;
	}

	.pagination-control input[type="text"] {
		font-size: 12px;
		width: 35px;
		text-align: left;
		padding: 5px;
	}

	.pagination-control > *:first-child {
		margin-left: 0;
	}

	.table-pagination {
		margin-top: 17px;
		margin-bottom: 20px;
	}

	.pagination-control input + span {
		margin-left: 5px;
	}

	.pagination-control input[type="text"] {
		margin-left: 5px;
	}
}

@media (max-width: 639px) {
	.form-main {
		padding-bottom: 30px;
	}
	.field-left {
		width: 100%;
		margin-bottom: 7px;
	}

	.field-right {
		width: 100%;
	}

	.form-main {
		padding: 15px 15px 0px;
		margin-top: 0;
	}

	.form_field {
		padding-right: 0;
		margin-bottom: 21px;
	}

	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		font-size: 12px;
		min-width: 108px;
		padding: 7px 20px;
	}

	.form-submit .button-field input {
		min-width: 108px;
	}

	.form-submit .button-field + .button-field {
		margin-left: 0;
		margin-right: 15px;
	}

	.form-submit .button-field {
		margin-bottom: 15px;
	}

	.form-submit .button-field {
		margin-right: 15px;
	}

	.custom-radio input[type="radio"] + label:before {
		width: 18px;
		height: 18px;
	}

	.custom-radio input[type="radio"] + label {
		line-height: 23px;
		padding-left: 25px;
	}

	.field-right .custom-radio {
		margin-top: 7px;
		margin-bottom: 5px;
	}
	.custom-radio input[type="radio"] + label:after {
		width: 12px;
		height: 12px;
		border-width: 4px;
	}
}
