.digital-welcome {
  padding-bottom: 14px;
}

.form-info {
  width: 150%;
}

.digital-welcome .sec-left {
  margin: 0 0;
  padding-bottom: 20px;
}




@media (max-width: 1199px) {
  .digital-welcome {
    padding-bottom: 10px;
  }

  .form-info {
    width: 150%;
  }
}
@media (max-width: 991px) {


  .digital-welcome .sec-left {
    padding-bottom: 0;
  }

  .digital-welcome {
    padding-bottom: 38px;
  }

  .form-info {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .digital-welcome .sec-left {
    margin-top: 10px;
  }

 
  .digital-welcome {
    padding-bottom: 20px;
  }
}
