.loc-content-main {
  padding: 0px 40px 0px 40px;
  display: block;
  background: #f5f7fa;
  box-shadow: 0px 0px 16px rgba(10, 10, 10, 0.06);
}

.location-inner-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 28px 0px 28px 0;
}

.location-screen-sec {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.location-screen-sec .MuiExpansionPanelDetails-root {
  padding: 0;
  display: block;
  background: #f5f7fa;
}

.location-screen-sec .MuiPaper-rounded {
  background: transparent;
  box-shadow: none;
}

.location-name {
  width: 45.3%;
}

.location-info {
  width: 25%;
}

.location-link {
  width: 19%;
}

.location-arrow-down {
  background: url("../../../assets/images/gray-down-arrow.svg") no-repeat right;
}

.location-arrow-up {
  background: url("../../../assets/images/gray-down-arrow.svg") no-repeat right;
}

.location-name .custom-checkbox label {
  font-weight: 400;
}

.app-main .table-body .custom-checkbox input[type="checkbox"]+label:after,
.location-name .custom-checkbox input[type="checkbox"]+label:after,
.map-sec-left .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url("../../../assets/images/white-check-ic.png");
  background-color: #1073c6;
  background-size: 12px;
}

.location-link a {
  color: #646464;
  text-align: center;
  padding: 5px 15px 5px 15px;
}

.red-link {
  color: #ff0000;
}

.location-link a:hover,
.location-link a:focus {
  text-decoration: none;
}

.location-info p {
  margin: 0;
  font-family: "Raleway", sans-serif;
}

.location-inner-content .custom-checkbox input[type="checkbox"]+label {
  padding-left: 30px;
  display: inline-block;
  vertical-align: middle;
}

.location-info p img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}

.location-screen-sec .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0;
}

.location-screen-sec .MuiExpansionPanelSummary-content {
  margin: 0;
}

.location-screen-sec .MuiExpansionPanelSummary-root {
  min-height: 1px;
  padding: 0px;
  position: relative;
}

.accordian_title:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  /* background: url("../../../assets/images/gray-down-arrow.svg") no-repeat center
    center; */
  width: 16px;
}

.location-screen-sec .MuiExpansionPanelSummary-content span {
  display: block;
  line-height: 1;
}

.loc-content-main .location-inner-content:last-child {
  border-bottom: none;
  align-content: center;
}

.business-info-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 -25px;
  padding: 0 10px;
}

.info-col-left {
  width: 53%;
  padding: 0 25px;
}

.info-col-right {
  width: 47%;
  padding: 0 25px;
}

.app-main .white-box {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 25px;
}

.white-box:last-child {
  margin-bottom: 0;
}

.box-head {
  padding: 30px;
  border-bottom: 3px solid #f5f7fa;
}

.app-main .box-head h4 {
  font-size: 20px;
  color: #177bc9;
  margin: 0;
  text-transform: capitalize;
}

.box-head h4 i {
  font-weight: 400;
}

.box-body {
  padding: 28px 30px;
}

.field-full {
  margin-bottom: 15px;
  position: relative;
}

.box-body>.field-full:last-child {
  margin-bottom: 0px;
}

.field-double {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.field-double .field-full {
  width: 50%;
  padding: 0 10px;
}

.preview-img {
  display: table;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  max-width: 300px;
}

.preview-img img {
  display: block;
}

.box-body .button-field button {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 22px;
  min-width: 133px;
}

.box-body .button-field+.button-field {
  margin-left: 20px;
}

.info-col-full {
  width: 100%;
  margin-top: 50px;
  padding: 0 10px;
}

.table-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.hours-table {
  padding-bottom: 7px;
}

.table-body-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.table-body-row:last-child {
  margin-bottom: 0;
}

.table-data h5 {
  margin: 0;
}

.table-width-1 {
  width: 20%;
}

.table-width-2 {
  width: 30%;
}

.table-width-3 {
  width: 30%;
}

.table-width-4 {
  width: 20%;
}

.head-col h5 {
  margin: 0;
}

.body-field {
  padding: 10px 0 0;
}

.body-field input[type="text"],
.body-field select {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  min-width: 315px;
}

.body-field select {
  margin-left: 20px;
}

.business-info-form {
  width: 100%;
  padding: 30px 0 30px;
}

.table-body .MuiInput-underline:before {
  display: none;
}

.table-body .MuiInput-underline:after {
  display: none;
}

.table-body .MuiInputBase-input {
  background: #f5f7fa;
  font-size: 16px;
  font-weight: 400;
  color: #646464;
  font-family: "Raleway", sans-serif;
  width: auto;
  height: auto;
  border-radius: 2px;
  padding: 13px 9px;
}

.location-screen-sec .MuiPaper-root.MuiExpansionPanel-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  margin: 0;
}

.accordian_title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  position: relative;
  margin-bottom: 8px;
}

.title-left h3 {
  color: #646464;
  margin: 0;
  line-height: 1;
}

.title-right p {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  color: #646464;
}

.title-left {
  width: 86.5%;
}

.title-right {
  width: 13.5%;
}

#upload-btn {
  display: none;
}

#upload-btn1 {
  display: none;
}

.file-upload-btn label {
  font-weight: 700;
  color: #fff;
  border-radius: 50px;
  min-width: 140px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Raleway", sans-serif;
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.map-sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0px 0px 0;
}

.map-sec-left {
  width: 20%;
  padding-right: 15px;
}

.map-sec-right {
  width: 80%;
  padding: 3px 0 0;
}

.map-sec-left .custom-checkbox {
  margin-bottom: 27px;
}

.map-sec-left .custom-checkbox:last-child {
  margin-bottom: 0;
}

.map-sec-left .custom-checkbox input[type="checkbox"]+label {
  padding-left: 42px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
}

.confirm-update {
  margin-top: 20px;
  margin-left: 0px;
  min-width: 215px;
}

.table-data .custom-checkbox input[type="checkbox"]+label:after,
.table-data .custom-checkbox input[type="checkbox"]+label:before {
  width: 28px;
  height: 28px;
}

.table-data .custom-checkbox input[type="checkbox"]+label {
  margin-top: -5px;
}

.field-full {
  position: relative;
}

.field-full .error-msg {
  font-size: 14px;
  padding-right: 45px;
}

.field-full .error-msg:after {
  width: 15px;
  background-size: 15px;
}

@media (max-width: 1500px) {
  .location-name {
    width: 59.5%;
  }

  .location-info {
    width: 14%;
  }

  .location-link {
    text-align: center;
    width: 26%;
  }

  .business-info-wrap {
    margin: 0 -10px;
  }

  .info-col-left {
    width: 50%;
    padding: 0 10px;
  }

  .info-col-right {
    width: 50%;
    padding: 0 10px;
  }

  .business-info-form {
    padding: 0px 0 0;
  }

  .box-head {
    padding: 18px;
  }

  .box-body {
    padding: 20px;
  }

  .field-double .field-full {
    width: 100%;
  }

  .table-width-1 {
    width: 15%;
  }

  .table-width-2 {
    width: 34%;
  }

  .table-width-3 {
    width: 34%;
  }

  .table-width-4 {
    width: 17%;
    text-align: center;
  }

  .business-info-wrap {
    padding: 0;
  }

  .white-box {
    margin-bottom: 30px;
  }

  .info-col-full {
    padding: 0;
    margin-top: 25px;
  }

  .table-body .MuiInputBase-input {
    font-size: 13px;
    padding: 13px 5px;
  }

  .map-sec-right {
    width: 65%;
  }

  .map-sec-left {
    width: 35%;
  }
}

@media (max-width: 1199px) {
  .location-name {
    width: 49%;
  }

  .location-info {
    width: 19%;
  }

  .location-link {
    width: 32%;
  }

  .accordian_title {
    padding: 20px 40px 20px 25px;
  }

  .title-left h3,
  .title-right p {
    font-size: 20px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label,
  .location-info p,
  .location-link a {
    font-size: 14px;
  }

  .loc-content-main {
    padding: 0 30px;
  }

  .location-inner-content {
    padding: 26px 0;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label {
    padding-left: 34px;
  }

  .info-col-left {
    width: 100%;
    margin-bottom: 30px;
  }

  .info-col-right {
    width: 100%;
  }

  .field-double .field-full {
    width: 50%;
  }

  .body-field input[type="text"],
  .body-field select {
    min-width: 255px;
  }

  .map-sec-left {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .map-sec-right {
    width: 100%;
  }

  .map-sec-left .custom-checkbox {
    width: 33.33%;
    padding-right: 10px;
    margin-bottom: 15px;
  }

  .map-sec-left .custom-checkbox input[type="checkbox"]+label {
    font-size: 14px;
  }

  .map-sec-right>div {
    height: 350px !important;
  }
}

@media (max-width: 991px) {

  .title-left h3,
  .title-right p {
    font-size: 24px;
  }

  .accordian_title {
    padding: 19px 20px;
    margin-bottom: 0;
  }

  .location-screen-sec {
    margin-top: 26px;
  }

  .loc-content-main {
    padding: 0 20px;
  }

  .location-inner-content {
    padding: 28px 0 27px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label,
  .location-info p,
  .location-link a {
    font-size: 16px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label {
    padding-left: 30px;
  }

  .location-name {
    width: 49%;
  }

  .location-info {
    width: 18.5%;
  }

  .location-link {
    width: 32.5%;
  }

  .title-left {
    width: 82.5%;
  }

  .title-right {
    width: 17.5%;
  }

  .box-head {
    padding: 20px 20px 18px;
  }

  .field-full {
    margin-bottom: 19px;
  }

  .white-box {
    margin-bottom: 28px;
  }

  .preview-img {
    width: 150px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .preview-img img {
    max-width: 150px;
  }

  .image-link-btn {
    display: inline-block;
    vertical-align: top;
    padding-left: 40px;
  }

  .image-link-btn .button-field {
    display: table;
    margin-bottom: 19px;
  }

  .box-body .button-field+.button-field {
    margin-left: 0;
  }

  .file-upload-btn label,
  .box-body .button-field button {
    font-size: 16px;
    line-height: 18px;
    min-width: 145px;
    padding: 12px 23px;
  }

  .head-col h5 {
    font-size: 16px;
    letter-spacing: 0;
  }

  .head-col {
    padding: 0 0 0;
  }

  .table-width-1 {
    width: 19.5%;
  }

  .table-width-2 {
    width: 28%;
  }

  .table-width-3 {
    width: 28.5%;
  }

  .table-width-4 {
    width: 24%;
    text-align: left;
  }

  .table-head {
    margin-bottom: 30px;
  }

  .table-data h5 {
    font-size: 16px;
  }

  .table-body .MuiInputBase-input {
    font-size: 16px;
    padding: 13px 9px;
  }

  .table-body-row {
    margin-bottom: 7px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label:after,
  .table-body .custom-checkbox input[type="checkbox"]+label:before {
    width: 28px;
    height: 28px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label {
    margin-top: -30px;
    display: inline-block;
    vertical-align: middle;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 13px;
  }

  .hours-table {
    padding-bottom: 0;
  }

  /* .body-field input[type="text"],
	.body-field select {
		min-width: 305px;
	} */
  .map-sec {
    padding-bottom: 0;
    padding-right: 0;
  }

  .map-sec-left .custom-checkbox input[type="checkbox"]+label {
    font-size: 15px;
    padding-left: 30px;
  }

  .map-sec-left .custom-checkbox {
    width: 31.5%;
    margin-bottom: 28px;
  }

  .map-sec-right {
    padding: 4px 0 0;
  }

  .map-sec-right>div {
    height: 370px !important;
  }

  .confirm-update {
    margin-top: 30px;
    min-width: 190px;
  }
}

@media (max-width: 767px) {
  .location-screen-sec .MuiExpansionPanelDetails-root {
    overflow: auto;
  }

  .title-left h3,
  .title-right p {
    font-size: 16px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label,
  .location-info p,
  .location-link a {
    font-size: 14px;
  }

  .location-name {
    width: 54%;
  }

  .location-info {
    width: 15%;
  }

  .location-link {
    width: 31%;
  }

  .custom-checkbox input[type="checkbox"]+label:after,
  .custom-checkbox input[type="checkbox"]+label:before {
    width: 13px;
    height: 13px;
  }

  .location-name .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 8px;
  }

  .location-screen-sec {
    margin-top: 0;
  }

  .accordian_title {
    padding: 14px 16px;
  }

  .location-screen-sec .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 1px;
  }

  .loc-content-main {
    padding: 0 15px;
    min-width: 620px;
  }

  .location-inner-content {
    padding: 18px 0;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label {
    padding-left: 24px;
    line-height: 15px;
  }

  .title-left {
    width: 79%;
  }

  .title-right {
    width: 21%;
  }

  .accordian_title:after {
    right: 12px;
  }

  .business-info-form {
    width: calc(100vw - 60px);
  }

  .box-head h4 {
    font-size: 16px;
  }

  .business-info-form {
    padding: 20px 0 0;
  }

  .box-head {
    padding: 15px 15px;
  }

  .box-body {
    padding: 12px 15px;
  }

  .field-double .field-full {
    width: 100%;
  }

  .white-box {
    margin-bottom: 22px;
  }

  .info-col-left {
    margin-bottom: 22px;
  }

  .file-upload-btn label,
  .box-body .button-field button {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 16px;
    min-width: 115px;
  }

  .preview-img img {
    max-width: 100px;
  }

  .preview-img {
    width: 100px;
  }

  .image-link-btn {
    padding-left: 20px;
  }

  .image-link-btn .button-field {
    margin-bottom: 15px;
  }

  .head-col h5,
  .table-data h5 {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .table-width-1 {
    width: 14%;
  }

  .table-width-2 {
    width: 35%;
  }

  .table-width-3 {
    width: 36%;
  }

  .table-width-4 {
    width: 15%;
  }

  .table-head {
    margin-bottom: 20px;
  }

  .table-body .MuiInputBase-input {
    font-size: 12px;
    padding: 7px 0px 6px 6px;
    width: 87px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label:after,
  .table-body .custom-checkbox input[type="checkbox"]+label:before {
    width: 18px;
    height: 18px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label {
    margin-top: -22px;
  }

  .table-body-row {
    margin-bottom: 5px;
  }

  .hours-table {
    padding-bottom: 9px;
  }

  .info-col-full {
    margin-top: 20px;
  }

  .loc-content-wrap {
    overflow: auto;
  }

  .map-sec-left .custom-checkbox {
    width: 50%;
    margin-bottom: 10px;
  }

  .map-sec-left .custom-checkbox input[type="checkbox"]+label {
    font-size: 12px;
    padding-left: 25px;
    line-height: 19px;
  }

  .body-field input[type="text"],
  .body-field select {
    width: 100%;
    margin: 0 0 20px;
    min-width: 1px;
    max-width: 285px;
  }

  .body-field select {
    margin-bottom: 0;
    display: block;
  }

  .map-sec-right>div {
    height: 160px !important;
  }

  .map-sec-right {
    padding: 6px 0 0;
  }

  .confirm-update {
    margin-top: 24px;
    min-width: 182px;
  }

  .confirm-update+.button-field.confirm-update {
    margin-left: 10px;
  }
}

@media (max-width: 639px) {
  .confirm-update {
    min-width: 170px;
  }
}

@media (max-width: 479px) {
  .confirm-update+.button-field.confirm-update {
    margin-top: 15px;
    margin-left: 0;
  }

  .confirm-update {
    display: table;
    margin-left: 0;
  }
}

@media (max-width: 370px) {
  .map-sec-left .custom-checkbox {
    width: 100%;
    padding: 0;
  }

  .map-sec {
    padding-top: 25px;
  }

  .map-sec-left {
    padding-bottom: 15px;
  }

  .table-body .MuiInputBase-input {
    padding: 7px 3px;
    font-size: 10.5px;
    width: 75px;
  }

  .table-width-2 {
    width: 38%;
  }

  .table-width-3 {
    width: 38%;
  }

  .table-width-4 {
    width: 10%;
  }

  .head-col h5,
  .table-data h5 {
    letter-spacing: 0;
    font-size: 11px;
  }

  .table-data.table-width-4 {
    text-align: right;
    padding-left: 6px;
  }

  .field-full .error-msg {
    font-size: 12px;
    padding-right: 25px;
  }

  .field-full .error-msg:after {
    right: 10px;
    width: 12px;
    background-size: 12px;
    background-position: center center;
  }
}